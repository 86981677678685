.equipment-box {
    .item {
        display: flex;
        align-items: center;
        padding: 8px 0;
        gap: 8px;

        > * {
            flex: 1;

            &.name {
                white-space: nowrap;
                line-height: 48px;
            }

            &.remove {
                min-width: 1em;
                flex: 0;
            }

            &.MuiFormControlLabel-root {
                flex: 0;
                margin-left: 0;
                margin-right: 0;
            }
        }

        &.artifact {
            > * {
                &:first-child {
                    flex: 0;
                }
            }
        }

        .MuiFormControlLabel-root {
            white-space: nowrap;
        }
    }
}

@media only screen and (max-width: 600px) {
    .equipment-box {
        .item {
            flex-direction: column;
            border: 1px solid rgba(0, 0, 0, .23);
            border-radius: 4px;
            margin-bottom: 16px;
            padding: 16px;
            > * {
                width: 100%;
                margin-bottom: 16px;

                &.remove {
                
                }
            }
        }
    }
}