.group {
	border-radius: 4px;
	border: 1px solid var(--border-light);
	position: relative;
	padding: 8px;
	padding-top: 12px;
	margin-top: 8px;
	box-sizing: border-box;
	width: 100%;

	h4 {
		background-color: white;
		display: inline;
		position: absolute;
		top: -34px;
		left: 16px;
		padding: 0 8px;
		text-transform: capitalize;
		font-weight: normal;
		color: var(--text-deemphasize);
	}
}