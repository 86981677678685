.merit {
    display: flex;

    > :first-child {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-right: 1rem;

        .MuiButtonBase-root {
            flex: 0;
        }
    }
    
    > :last-child {
        display: flex;
        align-items: center;
        width: 160px;
    }
}

@media only screen and (max-width: 600px) {
    .merit {
        flex-direction: column-reverse;
        gap: 16px;
        align-items: center;

        > :first-child {
            width: 100%;
        }
    }
}