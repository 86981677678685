.general-tab {
    .essence {
        text-align: center;

        label {
            display: block;
        }

        .buttons {
            .MuiButtonBase-root {
                margin: 0.5rem;
            }
        }
    }

    .dicepools {
        margin-top: 16px;
    }
}