.general {
    > *,
    > .MuiFormControl-root {
        margin-bottom: 16px;
    }

    .details > .MuiFormControl-root {
        margin-top: 1rem;
    }

    .intimacies {
        li {
            display: grid;
            grid-template-columns: 1rem 8rem 8rem calc(100% - 18rem - 64px) 1rem;
            grid-column-gap: 16px;
            
            .MuiSvgIcon-root {
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .general {
        .box.portrait {
            img {
                width: auto;
                margin: -0.5rem -16px -18px -16px;
            }
        }

        .intimacies {
            li {
                display: block;
                padding: 0;
            }
        }
    }
}