.map {
	position: relative;
    margin: -8px -16px -16px -16px;

    .viewport {
    	height: 600px;
        width: 100%;
        overflow: hidden;
    }

    .viewport.scrollable {
        overflow: auto;
    }

    canvas {
    	background-image: url('./map.jpg');
	    background-repeat: no-repeat;
	    background-size: contain;
	    margin: 0 auto;
	    display: block;
    }

    .zoom {
        position: absolute;
        top: 16px;
        right: 16px;
        height: calc(100% - 40px);
        border: 1px solid black;
        margin-right: 16px;
        width: 2px;
        border-radius: 2px;
        background-color: #DDDDDD;

        .handle {
            position: relative;
            cursor: pointer;
            left: -8px;
            border-radius: 4px;
            width: 16px;
            height: 8px;
            background-color: #DDDDDD;
            border: 1px solid black;
        }
    }
}