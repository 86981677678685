.charm {
    .keywords {
        grid-column-start: 2;
        grid-column-end: 4;

        .keyword {
            &:last-child:after {
                content: '';
            }
    
            &:after {
                content: ', ';
            }
        }
    
    }    

    .requirements > span {
        text-transform: capitalize;
        
        &:after {
            content: ', ';
        }

        &:last-child:after {
            content: '';
        }
    }

    .reqs {
        grid-column-start: 1;
        grid-column-end: 4;
        
        a {
            margin-right: 0.25em;
        }

        a:after {
            content: ',';
        }

        a:last-child:after {
            content: '';
        }
    }
}