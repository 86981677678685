.charms-tab {
    .selectors {
        display: flex;

        > * {
            margin: 0 16px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .box {
        margin: 16px 0;
        
        .MuiListItem-root {
            padding-left: 0;
            padding-right: 0;
        }

        .MuiSvgIcon-root {
            padding-left: 16px;
            padding-right: 0;
            margin-top: 16px;
        }
    }
}