.pc-list {
    height: 100%;

    .filters {
        padding: 8px;
        background-color: var(--secondary-light);
        box-shadow: var(--shadow);
        text-align: center;
    }

    .characters {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        overflow: auto;
        margin-top: 1rem;
        height: calc(100% - 4.75rem);
        padding: 0 0.5rem;

        .character {
            flex: 1;
            height: 320px;
            margin: 0 0.5rem 1rem 0.5rem;
            background-color: #DDDDDD;
            background-size: cover;
            background-position: top center;
            background-image: url('../silhouette.png');
            position: relative;
            cursor: pointer;
            max-width: 500px;

            h3 {
                background-color: var(--primary-translucent);
                color: var(--primary-contrast);
                margin: 0;
                padding: 0.5rem;
                position: absolute;
                bottom: 0;
                width: calc(100% - 1rem);
                text-align: center;
                font-size: 1rem;
                font-weight: 500;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                svg {
                    position: relative;
                    top: 0.225rem;
                    left: 0.5rem;
                }
            }

            &:last-child::after {
                content: "";
                flex: auto;
            }
        }
    }

    .MuiList-root {
        flex-grow: 1;
    }
}


@media only screen and (max-width: 600px) {
    .pc-list .characters {
        grid-template-columns: auto;
    }
}