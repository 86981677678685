.charm-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    .highlighted {
        color: var(--primary-light-contrast);
        background-color: var(--primary-light);

        &:hover {
            color: var(--primary-contrast);
            background-color: var(--primary);
        }
    }
}