.box {
	padding: 16px;
	position: relative;
	display: flex;
	flex-direction: column;

	&.centered {
		> .content {
			justify-content: center;
			align-items: center;
		}
	}

	&.primary {
		h3 {
			background-color: var(--primary);
			color: var(--primary-contrast);
		}
	}

	&.no-pad {
		padding: 0;

		> .content {
			padding: 0;
		}

		h3 {
			margin: 0;
		}

		.actions {
			margin: 0;
			margin-top: 16px;
		}
	}

	> .content {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	&.MuiPaper-root {
		box-shadow: var(--shadow);
	}

	&> h3 {
    	margin: -16px;
    	margin-bottom: 16px;
    	padding: 16px;
    	position: relative;
    	font-size: 1rem;
    	text-align: center;
    	background-color: var(--secondary);
		color: var(--secondary-contrast);
		text-transform: capitalize;
	}

	h4 {
		margin-bottom: 0;
	}

	.markdown > *:last-child {
		margin-bottom: 0;
	}

	.actions {
		margin: -1rem;
		margin-top: 2rem;
		display: flex;
		justify-content: right;
		align-items: center;
		gap: 1rem;
		background-color: var(--background-alt);
		padding: 1rem;
		border-top: 1px solid #DDDDDD;

		.MuiInputBase-root {
			padding: 0;
			background-color: white;
			
			> .MuiOutlinedInput-input {
				padding: 8px 16px;
			}
		}
	}
}

.grid .box {
	margin: 0;
}

@media only screen and (max-width: 600px) {
	.grid .box {
		h3 {
			padding: 8px;
			font-size: 14px;
		}
	}

	.box .grid {
		padding: 0;
	}

	.box {
		.actions {
			padding: 4px;
			margin: -16px;
			margin-top: 1rem;
		}
	}
}