.pc.edit {

    .details *:last-child {
        flex: 1;
    }

    .MuiDialog-paper {
        max-width: 1000px;
        width: 100%;

        .MuiDialogTitle-root {
            padding: 8px 24px;
        }

        .MuiDialogActions-root {
            .MuiButtonBase-root {
                flex: 1;
            }
        }
    }
}