.ui {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;

    .navigation-bar {
        height: 40px;
        background-color: var(--primary);
        z-index: 10;
    
        .version,
        .version a {
            color: var(--primary-dark);
        }
     
        .version {
            margin-left:auto;
        }
    }
    
    .toolbar.MuiAppBar-root {
        top: 40px;
        height: 88px;

        > .MuiToolbar-root {
            margin: 8px auto;
            width: 100%;
            box-sizing: border-box;

            .MuiTabs-indicator {
                background-color: var(--primary);
            }
        }
    }

    main {
        overflow-y: auto;
        flex: 1;
    }

	.MuiBottomNavigation-root {
		width: 100vw;
		height: 72px;
		display: flex;
		z-index: 1200;
		justify-content: center;
		background-color: var(--secondary);
		color: var(--secondary-contrast);
		
		.MuiBottomNavigationAction-root {
			color: inherit;

			.MuiBottomNavigationAction-label {
				margin-top: 5px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 12px;

        .ui {
            .MuiBottomNavigation-root {
                height: 48px;

                .MuiButtonBase-root {
                    padding: 6px;
                    min-width: 48px;
                }
            }
            
            .toolbar.MuiAppBar-root {
                height: 64px;
                
                > .MuiToolbar-root {
                    padding: 0;
                    margin: 4px auto;

                    .MuiTab-root {
                        min-height: auto;
                    }

                    .MuiButtonBase-root {
                        font-size: 10px;
                        padding: 6px 8px;
                    }

                    .MuiTabScrollButton-root {
                        padding: 0;
                        width: auto;
                    }
                }
            }
        }

        .MuiMenuItem-root {
            min-height: auto;
        }
    }
}