.abilities-tab {
    .attribute,
    .ability {
        display: flex;
        border-radius: 8px;
        padding: 4px 8px;

        &.favoured {
            background-color: var(--primary-light);
            .name {
                color: var(--primary-contrast);
            }
        }

        &.supernal {
            background-color: var(--primary);
            .name {
                color: var(--primary-dark-contrast);
            }
        }

		&.caste {
			color: var(--primary);
		}

        .name {
            margin-right: auto;
            text-transform: capitalize;
            line-height: calc(1em + 16px);
            width: 100%;
        }
    }

    .attribute {
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .abilities .grid {
        grid-column-gap: 16px;
        grid-row-gap: 8px;
    }

    .specialties {
        display: grid;
        grid-template-columns: 1fr 1fr 2em;
        grid-gap: 16px;
        align-items: center;

        .MuiSelect-select {
            text-transform: capitalize;
        }

        .training {
            background-color: var(--primary-light );
            border-radius: 8px;
        }
    }

    .martial-arts {
        display: grid;
        grid-template-columns: 1fr 8em 2em;
        grid-gap: 16px;
        align-items: center;    
    }
}