.name-value {
	width: 100%;
	
	> .content {
		display: flex;
		justify-content: space-between;
		align-items: end;

		.name {
			text-transform: capitalize;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline;
		}

		.value {
			color: var(--text-deemphasize);
			text-transform: capitalize;

			&.tooltipped {
				border-bottom: 1px dotted var(--secondary);
				cursor: help;			
			}
		}

		padding: 6px 16px;
	}

	&.strong {
		> .content {
			.name {
				font-weight: 500;
			}

			.value {
				color: var(--text);
			}
		}
	}

	&.emphasize-value {
		.name {
			color: var(--text-deemphasize);
		}
		.value {
			color: var(--text);
		}

		&.strong {
			> .content {
				.name {
					color: var(--text);
				}
				.value {
					font-weight: 500;
				}
			}
		}
	}

	&.no-cap {
		> .content {
			.name {
				text-transform: none;
			}
		}
	}

	&.condensed {
		> .content {
			justify-content: start;

			.name {
				padding-right: 0.5em;

				&:after {
					content: ':';
				}
			}
		}
	}
}
