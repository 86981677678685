.participants
 > div {
    display: grid;
	grid-gap: 12px;
    grid-template-columns: 174px auto 64px 174px 64px;
    align-items: center;
	justify-items: center;
    margin-top: 1rem;

	&.header {
		:nth-child(2) {
			grid-column-start: 2;
			grid-column-end: 4;
		}
	}
    
    svg {
        margin: 0 auto;
    }

    .character {
        padding: 0;
        text-align: left;
        display: flex;
        align-items: end;

        .MuiSvgIcon-root {
            vertical-align: middle;
            margin-top: 5px;

            &:first-child {
                padding: 0 16px 0 0;
            }

            &:last-child {
                padding: 0 0 0 16px;
            }
        }
    }
}