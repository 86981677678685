.health {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    text-align: center;
    font-weight: 500;
    height: 100%;

    > div {
        padding-top: 16px;
        
        &:nth-child(2n) {
            background-color: var(--background-alt);
        }
    }
}