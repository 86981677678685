.edit-combat {
	.box.attacks {
		> .content {
			> div {
				display: grid;
				grid-template-columns: 1fr 9rem 9rem 9rem 24px;
				grid-gap: 16px;
				padding: 16px;
			}
		}
	}

	@media (max-width: 950px) {
		.box.attacks {
			> .content {
				> div {
					grid-template-columns: 1fr 24px;
					grid-template-rows: 1fr 1fr 1fr 1fr;
				
					:nth-child(5n) {
						grid-column-start: 2;
						grid-column-end: 3;
						grid-row-start: 1;
						grid-row-end: 5;
					}
					
					.MuiInputBase-root {
						background: var(--background)
					}

					&:nth-child(2n) {
						background-color: var(--background-alt);
					}
				}
			}
		}
	}
}