.equipment.box {
    padding: 0;

    h3 {
        margin: 0;
        padding: 16px;
    }
    
    .actions {
        margin: 0 0 2px 0;
    }
}