:root {
	--error: #BB0000;
	--positive: #2e7d32;
	--bright: #ffc107;
	--background: #fafafa;
	--background-alt: #f5f5f5;
	--secondary-dark: #2e2e2e;
	--secondary: #424242;
	--secondary-light: #e0e0e0;
	--primary-dark: #b28704;
	--primary: var(--bright);
	--primary-light: #ffecb3;
	--contrast-dark: #000000;
	--contrast-light: #fafafa;
	--primary-translucent: rgba(255,193,7, 0.5);
	--primary-contrast: var(--contrast-dark);
	--primary-light-contrast: var(--contrast-dark);
	--primary-dark-contrast: var(--contrast-dark);
	--secondary-contrast: var(--contrast-light);
	--secondary-light-contrast: var(--contrast-light);
	--secondary-dark-contrast: var(--contrast-light);
	--border-light: #dddddd;
	--text-deemphasize: #A6A6A6;
	--shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
	--bold-weight: 500;
}

.solar {
	--primary-dark: #b28704;
	--primary: #ffc107;
	--primary-light: #ffecb3;
	--primary-translucent: rgba(255,193,7, 0.5);
}

.lunar {
	--primary-dark: #769eb2;
	--primary: #aae3ff;
	--primary-light: #dcefff;	
	--primary-dark-contrast: var(--contrast-dark);
	--primary-translucent: rgba(170,227,255, 0.5);
}

.dragonblooded {
	--primary-dark: #aa2e25;
	--primary: #f44336;
	--primary-light: #f6685e;	
	--primary-contrast: var(--contrast-light);
	--primary-translucent: rgba(244, 67, 54, 0.5);
}

.sidereal {
	--primary-dark: #404469;
	--primary: #8d71c4;
	--primary-light: #939bca;
	--primary-contrast: var(--contrast-light);
	--primary-translucent: rgba(55, 44, 77, 0.5);
}

.abyssal {
	--primary-dark: #444444;
	--primary: #888888;
	--primary-light: #BBBBBB;
	--primary-contrast: var(--contrast-light);
	--primary-translucent: rgba(136, 136, 136, 0.5);
}

.interactable {
	cursor: pointer;
}

.caps {
	text-transform: capitalize;
}

body, textarea, p, li, .normal {
	font-weight: 300;
	line-height: 1.6em;
}

h3, h4, h5, strong, th {
	font-weight: 500;
}

h4 {
	font-size: 1rem;
}

h5 {
	font-size: 1rem;
	font-style: italic;
	.normal();
}

body {
	margin: 0;
	padding: 0;
	font-family: Roboto, sans-serif;
	color: var(--contrast-dark);
	.normal();
	
	.MuiInputBase-input, .MuiMenuItem-root, .MuiTypography-body1 {
		.normal();
	}

	.MuiButtonBase-root {
		padding: 6px 16px;

		&.MuiSwitch-switchBase {
			padding: 9px;
		}

		&.MuiChip-root {
			padding: 0;
			margin: 0.25rem;
		}
		
		&.MuiCheckbox-root {
			padding: 0;
		}
	}

	.MuiPaper-root {
		color: var(--contrast-dark);
	}

	.MuiInputBase-root {
		padding: 0;

		> .MuiOutlinedInput-input {
			padding: 16px;
		}
	}

	.MuiTextField-root {
		&.full-height {
			height: 100%;

			.MuiInputBase-root {
				height: 100%;
			}
		}
	}
	
	.MuiFormLabel-root.Mui-focused {
		color: var(--primary) !important;
	}

	.MuiInput-underline:after {
		border-color: var(--primary) !important;
	}

	.MuiTooltip-tooltip {
		font-size: 16px;
		padding: 8px 16px;
	}

	.MuiAppBar-colorSecondary {
		background-color: var(--secondary);
		color: var(--secondary-contrast);
	}

	.MuiDialogTitle-root+.MuiDialogContent-root {
		padding-top: 16px;
	}

	.MuiDialog-paper .MuiDialogTitle-root {
		padding: 16px 24px;

		.MuiToolbar-root {
			margin: -8px -12px;
		}
	}

	.maxed .MuiDialog-paper {
		width: 100%;
		height: 100%;
	}

	.MuiTabs-root {
		margin: 0 auto;

		.MuiTab-root {
			color: var(--contrast-light);
			font-size: 13px;
			margin: 0 16px;
		}

		.Mui-selected {
			color: var(--contrast-light);
		}

		.MuiButtonBase-root {
			&:hover {
				background: transparent;
				color: var(--primary);
			}
		}
	}

	.MuiBottomNavigation-root {
		.MuiButtonBase-root:hover {
			background: transparent;
			color: var(--primary);
		}
	}

	.MuiDialog-paper {
		overflow: hidden;

		.MuiDialogTitle-root {
			background-color: var(--secondary);
			color: var(--secondary-contrast);
			font-weight: var(--bold-weight);

			.MuiSvgIcon-root {
				margin-right: 0.5rem;
				margin-top: -2px;
				vertical-align: middle;
			}

			.MuiPaper-root {
				box-shadow: none;
			}

			.MuiTabs-root {
				margin: 0 auto;
			}

			.MuiTextField-root {
				.MuiInputLabel-animated,
				input {
					color: var(--secondary-contrast);
				}
				.MuiInput-underline:before {
					border-color: var(--secondary-contrast) !important;
				}
			}
		}

		.MuiDialogActions-root {
			.dangerous {
				color: var(--error);
				margin-right: auto;
			}
		}
	}

	.MuiList-root {
		padding: 0;

		.MuiMenuItem-root {
			text-transform: capitalize;
			border-radius: 8px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.MuiPopover-paper {
		.MuiMenuItem-root {
			display: flex;
		}
	}

	.MuiButton-root {
		color: inherit;

		&.MuiButtonBase-root {
			padding: 6px 16px;
		}
		
		&.MuiButton-contained.primary,
		&.MuiButton-containedPrimary {
			&:not(.Mui-disabled) {
				background-color: var(--primary);
				color: var(--primary-contrast);
	
				&:hover {
					background-color: var(--primary-dark);
				}
			}
		}
	
		&.MuiButton-containedSecondary {
			&:not(.Mui-disabled) {
				background-color: var(--secondary);
				color: var(--secondary-contrast);
	
				&:hover {
					background-color: var(--secondary-dark);
				}
			}
		}
	
		&.MuiButton-containedSuccess {
			background-color:var(--positive);
			color: var(--contrast-light);
		}
		
		&.MuiButton-containedError {
			background-color:var(--error);
			color: var(--contrast-light);
		}	
	}

	.MuiCheckbox-colorPrimary.Mui-checked,
	.MuiSwitch-colorPrimary.Mui-checked {
		color: var(--primary);
	}

	.MuiSvgIcon-colorSecondary,
	.MuiCheckbox-colorSecondary.Mui-checked {
		color: var(--secondary);
	}

	.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track,
	.MuiTabs-indicator {
			background-color: var(--primary);
	}

	.MuiListItem.columns {
		display: grid;
	}

	.MuiFormControl-root {
		.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: var(--primary);
		}
	}

	.navigation-bar .MuiToolbar-root {
		min-height: 40px;
		background-color: var(--primary);
		color: var(--primary-contrast);
	}

	.tabs .MuiButtonBase-root {
		min-width: 0;
	}

	.toolbar {
		color: var(--secondary-contrast);
		background-color: var(--secondary);
	}

	.MuiDialog-scrollPaper {
		align-items: flex-start;
	}

	.MuiChip-label {
		text-transform: capitalize;
	}

	.MuiDialog-paper {
		> h2 {
			padding: 0;

			.MuiToolbar-root {
				padding: 0;

				.MuiButtonBase-root {
					font-size: 10px;
					padding: 6px 8px;
				}

				.MuiTabScrollButton-root {
					width: 24px;
				}
			}
		}
	}
}