.projects {
    .actions {
        span {
            margin-left: 1rem;
        }

        span:first-child {
            margin-left: 0;
        }

        .MuiSvgIcon-root {
            vertical-align: middle;
            margin-right: 0.125em;
        }

        .silver .MuiSvgIcon-root {
            color: #C0C0C0;
        }

        .gold .MuiSvgIcon-root {
            color: #d4af37;

        }

        .white .MuiSvgIcon-root {
            color: #FFFFFF;
        }
    }
}
