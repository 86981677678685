.health-level {
	display: inline-block;
	width: 16px;
	height: 16px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	margin: 8px;
	box-shadow: var(--shadow);
	
    &.bashing {
		background-color: var(--secondary-light);
    }
	
    &.lethal {
        background-color: var(--secondary);
    }
    
    &.aggravated {
		background-color: var(--bright);
	}
	
    &.disabled {
		background-color: var(--secondary-light);
	}
}