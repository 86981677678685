@import (reference) '../../../loom.less';

.excellency {
    .name {
        font-weight: var(--bold-weight);
    }
}

.supernal {
    .name {
        color: var(--primary);
    }
}