.dots-root {
    text-align: center;

    &.dark .dot {
        &.filled {
            background-color: var(--secondary-light);
        }
    
        &.selected {
            background-color: var(--secondary);
        }
    
        &.temporary {
            background-color: var(--secondary-light);
        }
        &.excess {
            background-color: var(--primary);
        }
    }

    &.light .dot {
        &.filled {
            background-color: var(--secondary-dark);
        }
    
        &.selected {
            background-color: var(--secondary);
        }
    
        &.temporary {
            background-color: var(--secondary-light);
        }
        &.excess {
            background-color: var(--primary);
        }
    }

    .dots {
        white-space: nowrap;
        line-height: calc(1em + 18px);
    }

    .dot {
        display: inline-block;
        border-radius: 0.5em;
        width: 1em;
        height: 1em;
        border: 1px solid rgba(0, 0, 0, 0.2);
        margin: 8px 4px;
        vertical-align: bottom;
        background-color: var(--background);
        
        &> span {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    
    .selectable {
        cursor: pointer;
    }

    .excess {
        margin-top: 8px;
        display: inline-block;
    }
}
