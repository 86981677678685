.MuiAutocomplete-popper {
	li {
		text-align: left;
		
		&.bold {
			font-weight: 500;
		}

		&.italic {
			font-style: italic;
		}
	}
}