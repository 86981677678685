.grid {
	display: grid;
    padding: 16px;
	box-sizing: border-box;
    width: 100%;
	grid-gap: 16px;

	&.centered {
		align-items: center;
	}

	&.no-gap {
		grid-gap: 0;
	}

	&.no-pad {
		padding: 0;
	}

	&.collapsed {
		grid-template-columns: 100% !important;

		.grid {
			grid-template-columns: 100% !important;
		}

		+ .grid.no-pad {
			margin-top: 16px;
		}
	}

	+ .grid {
		padding-top: 0;
	}
}


@media only screen and (max-width: 600px) {
	.grid.collapsed + .grid.no-pad {
		margin-top: 0;
	}
}