.general {
    .box.portrait {
        height: fit-content;

        h3 { margin-bottom: 0;}

        img {
            width: calc(100% + 2rem);
            margin: -0.5rem -1rem -23px -1rem;
            border-radius: 0 0 4px 4px;
        }
    }

    .notes {
        min-height: 20em;

        textarea {
            min-height: calc(20rem - 25px);
        }
    }
}