.list-tab {
    display: flex;
    flex-direction: column;

    .filters {
        padding: 8px;
        background-color: var(--secondary-light);
        box-shadow: var(--shadow);
        text-align: center;
    }

    .MuiList-root {
        overflow: auto;
    }
}