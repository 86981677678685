.dialog-actions {
	width: 100%;
	display: flex;
	gap: 8px;
	justify-content: space-between;

	> * {
		display: flex;
		gap: 8px;
	}
}