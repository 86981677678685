.intimacies {
	> .content {
		> div {
			display: grid;
			grid-gap: 16px;
			grid-template-columns: 1fr 8rem 8rem 24px;
			padding: 16px;
		}
	}
}

@media (max-width: 950px) {
	.box.intimacies {
		> .content {
			> div {
				grid-template-columns: 1fr 24px;
				grid-template-rows: 1fr 1fr 1fr;
			
				:nth-child(4n) {
					grid-column-start: 2;
					grid-column-end: 3;
					grid-row-start: 1;
					grid-row-end: 4;
				}
				
				.MuiInputBase-root {
					background: var(--background)
				}

				&:nth-child(2n) {
					background-color: var(--background-alt);
				}
			}
		}
	}
}

