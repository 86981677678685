.view-combat {
    .battle-group {
        .grid {
            grid-column-gap: 1rem;
        }
    }

    .attacks {
        .content {
            display: grid;
            grid-template-columns: 1fr 8em 8em 8em;
            grid-gap: 8px;

            > div {
                text-align: center;

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    font-weight: 500;
                }

                &:nth-child(4n + 1) {
                    text-align: left;
                }
            }
        }
    }
}