.markdown {
    h3 {
    	background-color: #424242;
    	border-radius: 15px;
    	color: #FFFFFF;
    	padding: 0.5rem 1rem;
		font-weight: normal;
    }


	a:hover, button:hover {
		text-decoration: underline;
	}

    a, button {
		color: var(--primary);
    	text-decoration: none;
    	background: none;
  		border: none;
  		padding: 0;
		font: inherit;
		cursor: pointer;
	}

    table {
    	border: 1px solid #424242;
    	border-radius: 5px;
    	border-collapse: collapse;
		width: 100%;
    }

    table th {
    	white-space: nowrap;
    	background-color: #424242;
    	color: white;
		font-weight: normal;
    }
    th, td {
    	padding: 0.5rem;
    	border-right: 1px solid #424242;
    }

    tr:nth-child(2n) {
    	background-color: #fff8e3;
    }

	li {
		margin-bottom: 0.5rem;
	}
}