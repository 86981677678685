.experience-tab {
    text-align: center;

    .spinner {
        .MuiTextField-root {
            width: 5rem;
        }

        margin: 0 16px;
    }

    .training {
        .MuiMenuItem-root {
            display: grid;
            grid-template-columns: 1fr 6rem 6rem 7rem;
            grid-gap: 16px;
            text-align: right;

            :nth-child(1) {
                text-align: left;
            }

            .MuiButton-root {
                width: 100%;
            }
        }
    }

    .expenditure {
        .MuiListItem-root {
            display: flext;
            cursor: pointer;

            .MuiListItemText-root:first-child {
                margin-right: auto;
            }

            .MuiListItemText-root:last-child {
                flex: none;
            }
        }
    }
}

.training-time {
    .MuiInputBase-root > .MuiOutlinedInput-input {
        width: 2em;
        text-align: right;
        padding-right: 0;
    }
}