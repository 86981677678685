.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    .value {
        display: inline-block;
		margin: 0 8px;

        .MuiInputBase-root .MuiOutlinedInput-input {
            padding-right: 0;
        }

        .MuiInputBase-input {
            min-height: auto;
        }

        .MuiTypography-root {
            padding-right: 16px;
        }

        input {
            text-align: right;
            
        }
    }

    button {
		min-width: 36px;
		min-height: 24px;
    }

    &.no-max {
        .value {
            width: 5rem;

            .MuiInputBase-root .MuiOutlinedInput-input {
                padding-right: 16px;
            }

            input {
                text-align: center;
            }
        }
    }
}